<template>
  <div class="classesCenterLogin">
    <div class="login_cont">
      <img
        class="login_bg"
        v-if="PortalConfig.navigationMenuList[navOneMenuSort].portalTwoModuleConfigVOList != ''"
        :src="QiNiuPath + PortalConfig.navigationMenuList[navOneMenuSort].portalTwoModuleConfigVOList[navMenuSort].unknownImg"
        alt
      />
      <img
        class="login_bg"
        v-else
        :src="QiNiuPath + PortalConfig.navigationMenuList[navMenuSort].unknownImg"
        alt
      />
      <div class="login_cont_left">
        <div class="login_user">
          <img class="user_img" src="../assets/imgs/head_img.png" alt />
          <p class="user_name">请登录</p>
        </div>
        <div class="desc">登录后可获取更多精彩内容</div>
        <button class="login_btn" @click="toLogin">立即登录</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Login } from "@/assets/js/login.js";

export default {
  data() {
    return {
      navtype: "", // 导航模块缺省
      navMenuSort: 0, //排序
      navOneMenuSort:0,//一级的index
    };
  },
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
      PortalConfig: (state) => state.indexStore.PortalConfig,
      MODELCODE: (state) => state.indexStore.MODELCODE,
    }),
  },
  created() {
    // 获取缺省类型
    this.navtype = this.$route.query.navtype;
    this.navMenuSort = Number(localStorage.getItem('sort'));
    this.navOneMenuSort = Number(localStorage.getItem('firstSort'));
    // console.log(JSON.stringify(this.PortalConfig.navigationMenuList[this.navOneMenuSort].portalTwoModuleConfigVOList));
    
  },
  methods: {
    // 去登陆
    toLogin() {
      let oldPageUrl = "";
      if (this.navtype == "medal") {
        // 如果路由类型是微认证
        oldPageUrl = window.location.origin + "/VMedal";
      } else if (this.navtype == "classes") {
        // 课程中心
        oldPageUrl = window.location.origin + "/VOfflineClasses";
      }else if(this.navtype=='certificate'){
        // 授课证
        oldPageUrl = window.location.origin + "/VCertificate";
      }
      this.defaultLogin(oldPageUrl);
    },
    
    // 缺省登录
    defaultLogin(oldPageURL) {
      let url = process.env.VUE_APP_LOGIN_Url;
      let code = 29;
      window.location.href =
        url + "/html/users/ubglogin.html?systemCode=" + code + "&" + oldPageURL;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin.scss";

.classesCenterLogin {
  background: rgba(255, 255, 255, 0.5);
  margin: 95px auto 140px auto;
  width: 1200px;
  position: relative;
  .login_cont {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .login_cont_left {
      @include wh(276px, 414px, 20px);
      border: 2px solid #5d5aed;
      background: url("../assets/imgs/login_bg.png") no-repeat center;
      background-size: cover;
      display: flex;
      align-items: center;
      flex-direction: column;
      // margin:16px 0px 0 0;
      position: absolute;
      left: 0;
      top: 16px;
      .login_user {
        margin-top: 48px;
        .user_img {
          @include wh(79px, 79px);
          margin-bottom: 8px;
        }
        .user_name {
          @include sc(28px, #5d5aed);
        }
      }
      .desc {
        @include sc(18px, #5d5aed);
        margin: 80px 0 23px 0;
      }
      .login_btn {
        @include wh(160px, 40px, 20px);
        @include sc(18px, #5d5aed);
        border: 1px solid #5d5aed;
        background: transparent;
        box-sizing: border-box;
      }
    }
    .login_bg {
      width: 1200px;
      height: 690px;
    }
    .login_cont_right {
      .right_bg {
        @include wh(914px, 611px);
      }
    }
  }
}
</style>